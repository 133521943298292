.login-button:hover {
  background-color: white !important;
  color: #002a5c !important;
}


.register-button:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.button {
  text-transform: none !important;
  background-color: white !important;
  color: #002a5c !important;
}
.button:hover {
  background-color: #002a5c !important;
  color: white !important;
}

.form-title {
  color: #002a5c;
  font-weight: 800 !important;
  padding: 5px 0px;
}

.form-button {
  background-color: #002a5c !important;
  color: white !important;
  align-self: flex-end;
}
.form-button:hover {
  background-color: white !important;
  color: #002a5c !important;
}
.form-button-disabled {
  align-self: flex-end;
}

.card {
    height: 100%;
}
.cardfooter {
    display: flex;
    height: 100%;
}

.form-field-title {
  margin: 25px 0px 5px 0px !important;
  font-weight: 800 !important;
}
